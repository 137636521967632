.card{
    display: flex;
    flex-direction: column;
    background: #000;
}
.header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem 1rem;
    background: rgb(52,53,65);
    color: rgb(217,217,227);
}
.title{
    display: flex;
    justify-content: space-between;
    width: 100%;
}