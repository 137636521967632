.markdown-custom-styles {
  color: inherit;
  background-color: transparent;
  line-height: 28px;
  white-space: pre-wrap;
  word-wrap: break-word;
  > p {
    margin-bottom: 0px;
    margin-block-end: 0;
    margin-block-start: 0;
  }
  >ol{
    counter-reset: li;
  }
  > ul, ol {
    display: flex;
    flex-direction: column;
    list-style: decimal;
    margin-bottom: 0px;
    margin-top: 0px;
    padding-left: 20px;
  }
  li{
    margin-top: 0px !important;
    margin-bottom: 0px;
  }

  
  & li p {
    margin-top: 0px;
    margin-bottom: 0px;
  }
  & pre {
    padding: 0;
    margin-top: 0px;
    margin-bottom: 0px;
  }
  & pre code {
    white-space: pre-wrap;
    padding: 10px;
  }
}
.markdown-body li+li {
  margin-top: 0 !important;
}