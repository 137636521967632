/*倒入antd的css文件 */

html,
body,
#root {
  height: 100%;
}
 

.border{
  border: 1px solid #ededed;
}


/* antd */

.ant-btn-primary{
  background-color: #01875f;
  border-color: #01875f;
  color: white;
}