@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
   
}

/* Ant Design Tree */
.ant-tree-node-selected {
  background-color: rgba(52, 53, 65, 1) !important;
  color: #fff !important;
}

/* tailwind  */
.h-screen {
  height: 100vh;
}

.font-logo {
  font-family: "Baloo Bhai 2", cursive;
}
.text-brand-green {
  color: #01875f;
  fill: #01875f;
  stop-color: #01875f;
}

.link {
  color: #01875f;
  line-height: 1.5rem;
  font-size: 1rem;
  letter-spacing: 0.00625em;
  font-weight: 500;
}
.meta-tag {
  color: rgb(32, 33, 36);
  fill: rgb(32, 33, 36);
  stop-color: rgb(32, 33, 36);
  line-height: 1.25rem;
  font-size: 0.875rem;
  letter-spacing: 0.0178571429em;
  font-weight: 500;
  align-items: center;
}
.meta-label {
  color: rgb(95, 99, 104);
  fill: rgb(95, 99, 104);
  stop-color: rgb(95, 99, 104);
  line-height: 1rem;
  font-size: 0.75rem;
  letter-spacing: 0.025em;
  font-weight: 400;
  align-items: center;
  display: flex;
  height: 20px;
  justify-content: center;
  white-space: nowrap;
}
.black-hightlight {
  color: #202124;
}
.h2{
  color: rgb(32,33,36);
  line-height: 1.75rem;
  font-size: 1.375rem;
  letter-spacing: 0;
  font-weight: 400;
  font-weight: 500;
}
.desc{
  color: rgb(95,99,104);
  fill: rgb(95,99,104);
  stop-color: rgb(95,99,104);
  font-size: .875rem;
  font-weight: 400;
  letter-spacing: .0142857143em;
  line-height: 1.25rem;
  font-family: Roboto,Helvetica,Arial,sans-serif;
}

.avatar-uploader > .ant-upload {
  width: 180px !important;
  height: 180px !important;
}

.svg-icon {
  color: rgb(95, 99, 104);
  fill: rgb(95, 99, 104);
  stop-color: rgb(95, 99, 104);
}
.title{
  color: rgb(32,33,36)
}
.label{
  color: rgb(95, 99, 104);
}
.cover-shadow {
  box-shadow: 0 1px 2px 0 rgba(60,64,67,.3), 0 1px 3px 1px rgba(60,64,67,.15);
}
.header-shadow {
  box-shadow: 0 4px 5px 0 rgba(0,0,0,.14), 0 1px 10px 0 rgba(0,0,0,.12), 0 2px 4px -1px rgba(0,0,0,.2);
}

.react-horizontal-scrolling-menu--scroll-container  {
  /* Chrome, Safari, Edge */
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
}

.react-horizontal-scrolling-menu--scroll-container::-webkit-scrollbar {
  display: none; /* 针对 Chrome, Safari 和 Edge 的 WebKit 引擎 */
}